import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

export default function VerifyContainer() {
    let { id, code } = useParams();
    let [isLoaded, changeIsLoaded] = useState(false);
    let [isValid, changeIsValid] = useState(false); 

    useEffect( () => {
        const data = {
            "id": id,
            "code": code
        };
    
        fetch("/api/verifyUser", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(response.ok) return response.json();
            else response.json().then(msg => {
                alert(msg.message);
                changeIsLoaded(true);
                changeIsValid(false);
            });
        })
        .then(data => {
          if(!data) return;
          changeIsLoaded(true);
          changeIsValid(true);
        })
        .catch(error => console.log(error))
    },[code, id])

    const showResult = function(success){
        if(!isValid) return "User already verified or invalid url"

        return "Successfully verified user"
    }

    return (
        <>
            <div style={{textAlign: "center", fontSize: "24px"}}>
                {isLoaded ? showResult() : "Processing..."}
            </div>
        </>
    )
}