import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import AddChecklistItem from './AddChecklistItem';
import './FullChecklist.css';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import uuid from 'react-uuid'
import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function NewChecklistComponent() {
    const [checklistName, changeChecklistName] = useState("");
    const [checklistProcedure, changeProcedureName] = useState("");
    const [checklistId, setChecklistId] = useState(0);
    const [preChecklist, setPreChecklist] = useState([{"tasks":[{"enabled": true}]}])
    const [postChecklist, setPostChecklist] = useState([{"tasks":[{"enabled": true}]}])
    const [showPre, setShown] = useState(true)
    const [showModal, handleModal] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    let { id, showTab } = useParams();
    const timeTypes = ["hour", "day", "week", "month"];

    useEffect( () => {
        if(id === undefined) return;
        const token = localStorage.getItem("token");
        const pingUrl = "/api/checklist?checklistId=" + id;

        fetch(pingUrl,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        .then(response => response.json())
        .then(data => {
            let newChecklist = sortChecklist(data);
            changeChecklistName(newChecklist.name);
            changeProcedureName(newChecklist.surgeryProcedure)
            setPreChecklist(newChecklist.pre);
            setPostChecklist(newChecklist.post);
            if(showTab == "false"){
                setShown(false);
            }
        })
    },[id])

    const sortChecklist = function(checklistData){
        checklistData.pre.sort((a, b) => {
            if(timeTypes.indexOf(a.type) === timeTypes.indexOf(b.type)){
                if (a.timeFrame < b.timeFrame) {
                    return -1;
                }
                if (a.timeFrame > b.timeFrame) {
                    return 1;
                }
                return 0;
            }
            if(timeTypes.indexOf(a.type) < timeTypes.indexOf(b.type)){
                return -1;
            }
            if(timeTypes.indexOf(a.type) > timeTypes.indexOf(b.type)){
                return 1;
            }
        });
    
        checklistData.post.sort((a, b) => {
            if(timeTypes.indexOf(a.type) === timeTypes.indexOf(b.type)){
                if (a.timeFrame < b.timeFrame) {
                    return -1;
                }
                if (a.timeFrame > b.timeFrame) {
                    return 1;
                }
                return 0;
            }
            if(timeTypes.indexOf(a.type) < timeTypes.indexOf(b.type)){
                return -1;
            }
            if(timeTypes.indexOf(a.type) > timeTypes.indexOf(b.type)){
                return 1;
            }
        });

        return checklistData;
    }
    

    const customStyles = {
        content: {
            width: '15%',
            minWidth: "300px",
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            borderRadius: '9px'
        },
    };

    function showTabStyle(isPreTab){
        if(isPreTab){
            if(showPre) return {display: "block"};
            else return {display: "none"};
        }else{
            if(showPre) return {display: "none"};
            else return {display: "block"};
        }
    }

    function styleTab(isPre){
        if(isPre){
            if(showPre) return {};
            return {backgroundColor: "#F5F6FA", borderBottom:"solid", borderWidth:"thin", borderColor:"rgba(128, 128, 128, 25%)"};
        }else{
            if(showPre) return {backgroundColor: "#F5F6FA", borderBottom:"solid", borderWidth:"thin", borderColor:"rgba(128, 128, 128, 25%)"};
            else return {};
        }
        
    }

    function addNewItem(){
        if(showPre) setPreChecklist([...preChecklist, {"type": "", "tasks":[{"enabled":true, "data":""}]}]);
        else setPostChecklist([...postChecklist, {"type": "", "tasks":[{"enabled":true, "data":""}]}]);
    }
    

    const removeItem = function(type, index){
        if(type === "pre"){
            setPreChecklist(
                listItems => listItems.filter( (element, idx) =>  idx !== index)
            )
        }else{
            setPostChecklist(
                listItems => listItems.filter( (element, idx) =>  idx !== index)
            )
        }
    }

    const changeGlobal = function(parentKey, type, index, value){
        if(type === "pre"){
            preChecklist[parentKey].tasks[index].data = value;
            setPreChecklist(preChecklist);
        }else{
            postChecklist[parentKey].tasks[index].data = value;
            setPostChecklist(postChecklist);
        }
    }

    const changeAdditionalGlobal = function(parentKey, type, index, value){
        if(type === "pre"){
            const temp = [...preChecklist];
            temp[parentKey].tasks[index].additionalInfo = value;
            setPreChecklist(temp);
        }else{
            const temp = [...postChecklist];
            temp[parentKey].tasks[index].additionalInfo = value;
            setPostChecklist(temp);
        }
    }

    const removeGlobal = function(parentKey, type, index){
        if(type === "pre"){
            const temp = [...preChecklist];
            temp[parentKey].tasks = temp[parentKey].tasks.filter( (element, idx) => idx !== index);
            setPreChecklist(temp);
        }else{
            const temp = [...postChecklist];
            temp[parentKey].tasks = temp[parentKey].tasks.filter( (element, idx) => idx !== index);
            setPostChecklist(temp);
        }
    }

    const addGlobal = function(parentKey, type){
        const newTask = {enabled: true, data: ""};
        if(type === "pre"){
            const temp = [...preChecklist];
            temp[parentKey].tasks.push(newTask);
            setPreChecklist(temp);
        }else{
            const temp = [...postChecklist];
            temp[parentKey].tasks.push(newTask);
            setPostChecklist(temp);
        }
    }

    const changeTimeFrame = function(index, type, newTimeFrame){
        if(type === "pre"){
            preChecklist[index].timeFrame = newTimeFrame;
            setPreChecklist(preChecklist);
        }else{
            postChecklist[index].timeFrame = newTimeFrame;
            setPostChecklist(postChecklist);
        }
    }

    const changeTimeType = function(index, type, newTimeType){
        if(type === "pre"){
            const temp = [...preChecklist];
            temp[index].type = newTimeType;
            setPreChecklist(temp);
        }else{
            const temp = [...postChecklist];
            temp[index].type = newTimeType;
            setPostChecklist(temp);
        }
    }

    function verifyData(){
            let tempPre = [...preChecklist];
            let tempPost = [...postChecklist];
            for(let i = 0; i < tempPre.length; i++){
                tempPre[i].tasks = tempPre[i].tasks.filter( (element) => element.data !== "");
                tempPre[i].tasks = tempPre[i].tasks.filter( (element) => element.data !== undefined);
            }
            tempPre = tempPre.filter( (element) => element.tasks.length !== 0);

            for(let i = 0; i < tempPre.length; i++){
                if( tempPre[i].timeFrame === undefined ||
                    tempPre[i].type === undefined ||
                    tempPre[i].type === "" ||
                    tempPre[i].timeFrame === 0){
    
                    alert(t("Please input a proper time and period for all the pre-operative sections"));
                    return null;
                }
            }

            setPreChecklist(tempPre);

            for(let i = 0; i < tempPost.length; i++){
                tempPost[i].tasks = tempPost[i].tasks.filter( (element) => element.data !== "");
                tempPost[i].tasks = tempPost[i].tasks.filter( (element) => element.data !== undefined);
            }

            tempPost = tempPost.filter( (element) => element.tasks.length !== 0);

            for(let i = 0; i < tempPost.length; i++){
                if( tempPost[i].timeFrame === undefined ||
                    tempPost[i].type === undefined ||
                    tempPost[i].type === "" ||
                    tempPost[i].timeFrame === 0){
    
                    alert(t("Please input a proper time and period for all the post-operative sections"));
                    return null;
                }
            }

            setPostChecklist(tempPost);

            if(tempPre.length === 0 && tempPost.length === 0){
                alert(t("Cannot save blank checklist, please add in data"));
                return null;
            }

            return {
                "pre": tempPre,
                "post": tempPost
            };
    }

    function saveHandler(){
        let isErrors = false;
        //TODO: Add check for blank checklist
        if(checklistName === ""){
            alert(t("Please put checklist name"));
            isErrors = true;
        }else if( checklistProcedure === ""){
            alert(t("Please put procedure name"));
            isErrors = true;
        }

        let fullChecklist = verifyData();

        if(isErrors || fullChecklist === null) return;

        if(id !== undefined) modifyChecklist(fullChecklist)
        else createChecklist(fullChecklist);
    }

    function modifyChecklist(checklist){
        const token = localStorage.getItem("token");
        const data = {
            checklistId: id,
            data: checklist,
            name: checklistName,
            surgeryProcedure: checklistProcedure
        };
        fetch("/api/checklist", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not modify checklist"));
        })
        .then(data => {
            //navigateToSaved(data.checklistId);
            setChecklistId(data.checklistId);
            handleModal( oldBool => !oldBool );
        })
        .catch(error => console.log(error))
    }

    function createChecklist(checklist){
        const token = localStorage.getItem("token");
        const data = {
            data: checklist,
            name: checklistName,
            surgeryProcedure: checklistProcedure
        };
        fetch("/api/checklist", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(response.ok) return response.json();
            else response.json().then(res => {
                alert(res.message);
            });
        })
        .then(data => {
            if(!data) return;
            const count = parseInt(localStorage.getItem("totalChecklists"));
            localStorage.setItem('totalChecklists', count + 1);
            setChecklistId(data.checklistId);
            handleModal( oldBool => !oldBool );
        })
    }

    function navigateToSaved(){
        const navUrl = "/checklist/" + checklistId;
        navigate(navUrl);
    }

    function navigateBack(){
        const navUrl = "/dashboard";
        navigate(navUrl);
    }

    return (
        <div className="full-checklist-container">
            <div className="add-new-header">
                <div className="full-checklist-generate-code mobile-width"> 
                            <span onClick={navigateBack} className="cancel-button"> {t("Cancel")} </span> 
                            <button onClick={saveHandler}> {t("Save Checklist")} </button> 
                </div>

                {t("Name")}
                <div>
                        <input maxLength="50" defaultValue={checklistName} onBlur={e => changeChecklistName(e.target.value)}></input> 
                </div>
                <br/>

                {t("Procedure")}
                <div> 
                    <input maxLength="50" defaultValue={checklistProcedure} onBlur={e => changeProcedureName(e.target.value)}></input>
                </div>
            </div>

            <div className="full-checklist-main-content">
                <div className="full-checklist-tabs">
                    <span className="tab-item" id="full-checklist-pre-tab" onClick={ () => setShown(true) } style={styleTab(true)} > {t("Pre-Operative Checklist")} </span>
                    <span className="tab-space"> </span>
                    <span className="tab-item" id="full-checklist-post-tab" onClick={ () => setShown(false)} style={styleTab(false)} > {t("Post-Operative Checklist")} </span>
                </div>


                <div className="full-checklist-all-data">
                    <div id="full-checklist-pre-data" style={showTabStyle(true)}>
                        {
                            preChecklist.map(
                                (item, index) => <AddChecklistItem isPre={showPre} changeTimeType={changeTimeType} changeTimeFrame={changeTimeFrame} addGlobal={addGlobal} removeGlobal={removeGlobal} changeGlobal={changeGlobal} changeAdditionalGlobal={changeAdditionalGlobal} items={item} removeSection={removeItem} key={uuid()} parentKey={index} type="pre"/>
                            )
                        }
                    </div>

                    <div id="full-checklist-post-data" style={showTabStyle(false)}>
                    {
                            postChecklist.map(
                                (item, index) => <AddChecklistItem isPre={showPre} changeTimeType={changeTimeType} changeTimeFrame={changeTimeFrame} addGlobal={addGlobal} removeGlobal={removeGlobal} changeGlobal={changeGlobal} changeAdditionalGlobal={changeAdditionalGlobal} items={item} removeSection={removeItem} key={uuid()} parentKey={index} type="post"/>
                            )
                    }
                    </div>

                    
                </div>

                <div onClick={addNewItem}  className="full-checklist-add-time-section">
                    <div id="full-checklist-post-data">
                        <span className="add-time-button"> {t("Add time section")} </span> <AiOutlinePlusCircle/>
                    </div>
                </div>

            </div>

            <Modal isOpen={showModal} style={customStyles} contentLabel="Minimal Modal Example">
                <div className="success-modal-box">
                    <div className="success-modal-header">{t("Success")}!</div>
                    <div className="success-modal-main-text">{t("Your checklist has been successfully saved")}</div>
                    <button onClick={() => navigateToSaved()} className="success-modal-btn">{t("Okay")}</button>
                </div>
            </Modal>



        </div>
    )
}
