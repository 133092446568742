import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BiTrash } from 'react-icons/bi';
import { RiFileInfoLine } from 'react-icons/ri'
import Modal from 'react-modal';

export default function ListItemInput(props) {
    const {listIndex, removeFunc, data, additionalInfo, modifyFunc, modifyAdditionalFunc} = props;
    const [inputValue] = useState(data);
    const [infoColor, changeInfoColor] = useState("#4b3e8a")
    const [additionalInfoVal] = useState(additionalInfo);
    const [closeModal, handleCloseModal] = useState(false);
    const additionalinfoRef = useRef();
    const [showModal, changeModal] = useState(false);
    const { t } = useTranslation();

    const modalStyle = {
        content: {
            width: '45%',
            minWidth: "300px",
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            borderRadius: '9px'
        },
    };

    useEffect( () => {
        if(additionalInfo === undefined || additionalInfo === "") changeInfoColor("rgb(75, 62, 138, 0.35)");
    }, [additionalInfoVal])

    const openAdditionalInfoModal = function(){
        changeModal(true);
    }

    const changeAdditonalInformation = function(itemIndex){
        modifyAdditionalFunc(itemIndex, additionalinfoRef.current.value);
        changeModal(false);
    }

    return (
        <div className="item-row-div">
            <span className="item-row">  
                <span className="side-text">{t("List item")}</span>
                <input  onBlur={ e => modifyFunc(listIndex, e.target.value) } defaultValue={inputValue}></input> 
                <RiFileInfoLine className="trashBtnModify" onClick={ () => openAdditionalInfoModal(listIndex) } style={{color: infoColor}}/>
                <BiTrash className="trashBtn" onClick={ () => removeFunc(listIndex) } style={{color: "grey", position: "relative", top: "2px", fontSize: "20px"}}/>
            </span>

            <Modal onRequestClose={() => changeModal(false)} isOpen={showModal} style={modalStyle} contentLabel="Minimal Modal Example">
                <div className="text-modal-box">
                    <div className="text-modal-header">{t("Additional Information")}</div>
                    <textarea ref={additionalinfoRef} maxLength='750' rows="8" className="text-modal-main-text" defaultValue={additionalInfoVal}></textarea>
                    <button onClick={() => changeAdditonalInformation(listIndex)} className="text-modal-btn">{t("Save")}</button>
                </div>
            </Modal>
        </div>
    )
}
