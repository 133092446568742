import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import ListItemInput from './ListItemInput';
import { BiTrash } from 'react-icons/bi';
import './FullChecklist.css';


export default function AddChecklistItem(props) {
    const [listItems] = useState(props.items.tasks)
    const [selectedButton] = useState(props.items.type);
    const [timeFrame] = useState(props.items.timeFrame);
    const [isPre] = useState(props.isPre);
    const { t } = useTranslation();
    const {removeSection, type, parentKey, changeGlobal, changeAdditionalGlobal, removeGlobal, addGlobal, changeTimeFrame, changeTimeType} = props;

    function addNewItem() {
        addGlobal(parentKey, type);
    }

    const deleteInput = function(index){
        removeGlobal(parentKey, type, index);
    }

    const changeInput = function(index, value){
        changeGlobal(parentKey, type, index, value);
    }

    const changeAdditional = function(index, value){
        changeAdditionalGlobal(parentKey, type, index, value);
    }

    const getButtonStyle = function(btnNum){
        if(selectedButton !== btnNum) return {backgroundColor: "white"};
        return {
            backgroundColor: "#4B3E8A",
            color: "white"
        }
    }

    const changeClickedButton = function(btnType){
        changeTimeType(parentKey, type, btnType);
    }

    const changeTime = function(e){
        if(e.target.value === '') return;
        const re = /^[0-9\b]+$/;
        if (e.target.value !== '' && !re.test(e.target.value)) {
            e.target.value = '';
            return;
        }
        changeTimeFrame(parentKey, type, parseInt(e.target.value));
    }

    const numberOnly = function(e){
        const re = /^[0-9\b]+$/;
        if (e.target.value !== '' && !re.test(e.target.value)) {
            e.target.value = e.target.value.slice(0, -1);
        }
    }

    return (
        <div className="add-item-container">
            <div className="time-options">
                <span onClick={ () => removeSection(type, parentKey)} className="remove-time-section trashBtn"> <BiTrash/> {t("Remove Section")}</span>
                {isPre ? <span className="side-text"> {t("Time Before")} </span> : <span className="side-text"> {t("Time After")} </span>} 
                <span> <input className="time-input" maxLength="2" onChange={e => numberOnly(e)} onBlur={e => changeTime(e)} defaultValue={timeFrame}></input> </span>
                <span className="time-buttons">  <span className="checklist-button-container"> <button onClick={() => changeClickedButton("hour")} style={getButtonStyle("hour")}>{t("Hours")}</button> <button onClick={() => changeClickedButton("day")} style={getButtonStyle("day")}>{t("Days")}</button> <button onClick={() => changeClickedButton("week")} style={getButtonStyle("week")}>{t("Weeks")}</button> <button onClick={() => changeClickedButton("month")} style={getButtonStyle("month")}>{t("Months")}</button> </span></span>
            </div>

                {
                    listItems.map(
                        (item, index) => (
                            <ListItemInput modifyFunc={changeInput} modifyAdditionalFunc={changeAdditional} removeFunc={deleteInput} listIndex={index} data={item.data} additionalInfo={item.additionalInfo} key={uuid()}/>
                        )
                    )
                }
                <div className="checklist-add-button" onClick={addNewItem}>{t("Add list item")} +</div>

            
        </div>
    )
}
