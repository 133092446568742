import React, { useState, useRef, useEffect } from "react";

export default function Dropdown(props) {
    const wrapperRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
          document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setIsVisible(false);
        }
    };

    const dropDownHandler = function(){
        isVisible ? setIsVisible(false) : setIsVisible(true);
    }

  return (
        <div className="dropbtn" ref={wrapperRef}>
            {props.isSettings && <span className="myDropdown" onClick={() => dropDownHandler()} >{props.dropText}</span>} 
            <div id="myDropdown" className="dropdown-content">
                {isVisible && props.data.map(
                    (item, idx) => <ul onClick={item.callBack} key={idx}>{item.text}</ul>
                )}
            </div>
        </div>
  )
}


{/* <a href="#" onClick={() => props.data[0].callBack()}><BiTrash/> Delete</a>
<a href="#"><AiOutlineEdit/> {props.data[1].logo}</a> */}