import React from 'react'
import Navbar from '../components/Navbar'
import NewChecklistComponent from '../components/NewChecklistComponent'

export default function NewChecklistContainer() {
    return (
        <div>
            <Navbar/>
            <NewChecklistComponent/>
            <div className="footer loginFooter">
                ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
            </div>
        </div>
    )
}
