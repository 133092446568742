import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import './FullChecklist.css';
import FullChecklistItem from './FullChecklistItem';
import { AiOutlineEdit, AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import uuid from 'react-uuid'
import { useParams } from 'react-router'
import { useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown"


Modal.setAppElement('#root');
var QRCode = require('qrcode.react');



const mockData =
{
    "name": "",
    "pre": [
        
    ],
    "post": [
        
    ]
};



export default function FullChecklist() {
    const emailRef = useRef();
    const noteRef = useRef();
    const [checklistData, changeChecklistData] = useState(mockData);
    const [showModal, handleModal] = useState(false);
    const [checklistCode, changeChecklistCode] = useState("");
    const [copyBtnText, changeCopyBtnText] = useState("Copy");
    const [noteBtnText, changeNoteBtnText] = useState("Add Note");
    const [showPre, setShown] = useState(true);
    const [isEmailInput, changeEmailInput] = useState(false);
    const [isSent, changeIsSent] = useState(false);
    const [checklistId, setChecklistId] = useState(null);
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const timeTypes = ["hour", "day", "week", "month"];
    

    checklistData.pre.sort((a, b) => {
        if(timeTypes.indexOf(a.type) === timeTypes.indexOf(b.type)){
            if (a.timeFrame < b.timeFrame) {
                return -1;
            }
            if (a.timeFrame > b.timeFrame) {
                return 1;
            }
            return 0;
        }
        if(timeTypes.indexOf(a.type) < timeTypes.indexOf(b.type)){
            return -1;
        }
        if(timeTypes.indexOf(a.type) > timeTypes.indexOf(b.type)){
            return 1;
        }
    });

    checklistData.post.sort((a, b) => {
        if(timeTypes.indexOf(a.type) === timeTypes.indexOf(b.type)){
            if (a.timeFrame < b.timeFrame) {
                return -1;
            }
            if (a.timeFrame > b.timeFrame) {
                return 1;
            }
            return 0;
        }
        if(timeTypes.indexOf(a.type) < timeTypes.indexOf(b.type)){
            return -1;
        }
        if(timeTypes.indexOf(a.type) > timeTypes.indexOf(b.type)){
            return 1;
        }
    });

    useEffect( () => {
        changeNoteBtnText(t("Add Note"));
        const token = localStorage.getItem("token");
        const pingUrl = "/api/checklist?checklistId=" + id;

        fetch(pingUrl,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        .then(response => response.json())
        .then(data => {
            changeChecklistData(data);
        })
    },[id])

    const customStyles = {
        content: {
            width: '30%',
            minWidth: "300px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            borderRadius: '9px'
        },
    };

    function showTabStyle(isPreTab){
        if(isPreTab){
            if(showPre) return {display: "block"};
            else return {display: "none"};
        }else{
            if(showPre) return {display: "none"};
            else return {display: "block"};
        }
    }

    function styleTab(isPre){
        if(isPre){
            if(showPre) return {};
            return {backgroundColor: "#F5F6FA", borderBottom:"solid", borderWidth:"thin", borderColor:"rgba(128, 128, 128, 25%)"};
        }else{
            if(showPre) return {backgroundColor: "#F5F6FA", borderBottom:"solid", borderWidth:"thin", borderColor:"rgba(128, 128, 128, 25%)"};
            else return {};
        }
    }

    const changedAvailability = function(type, parentIndex, childIndex, enabled ){
        const temp = {...checklistData};
        temp[type][parentIndex].tasks[childIndex].enabled = enabled;
        changeChecklistData(temp);
    };

    function modalHandler(){
        handleModal( oldBool => !oldBool );
        changeNoteBtnText(t("Add Note"));
    }

    function navigateModify(){
        const navUrl = "/modifychecklist/" + id +"/" + showPre;
        navigate(navUrl);
    }

    const deleteChecklist = function(){
        const token = localStorage.getItem("token");
        const pkg = {
            checklistId: parseInt(id)
        }

        fetch("/api/deleteChecklist", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not delete checklist"));
        })
        .then(data => {
            const count = parseInt(localStorage.getItem("totalChecklists"));
            localStorage.setItem('totalChecklists', count - 1);

            alert(t("Successfully deleted checklist"));
            navigate("/dashboard");
        })
        .catch(error => alert(error))
    }

    const modifyChecklist = function(){
        const navUrl = "/modifychecklist/" + id +"/" + showPre;
        navigate(navUrl);
    }

    function removeDisabled(){
        const generatedPre = removeTasks(checklistData.pre);
        const generatedPost = removeTasks(checklistData.post);
        let newData = {
            "pre": generatedPre,
            "name": checklistData.name,
            "post": generatedPost,
            "surgeryProcedure": checklistData.surgeryProcedure
        };
        return newData;
    }

    function removeTasks(tasks){
        let items = [];
        tasks.forEach(item => {
            let newTasks = [];
            item.tasks.forEach(task => {
                if(task.enabled !== false) newTasks.push(task);
            });

            if(newTasks.length !== 0){
                let itemObj = {
                    "type": item.type,
                    "tasks": newTasks,
                    "timeFrame": item.timeFrame
                };
                items.push(itemObj);
            }
        });

        return items;
    }

    function createCode(){
        changeEmailInput(false);
        if(isSent){
            changeIsSent(false);
        }

        changeCopyBtnText("Copy")
        const token = localStorage.getItem("token");
        const pkgList = removeDisabled();
        const pkg = {
            data: pkgList,
            checklistId: parseInt(id)
        }

        fetch("/api/createAppChecklist", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not generate checklist code"));
        })
        .then(data => {
            changeChecklistCode(data.code);
            setChecklistId(data.id);
            handleModal( oldBool => !oldBool );
        })
        .catch(error => alert(error))
    }

    function sendEmail(){
        const token = localStorage.getItem("token");
        if(emailRef.current.value === ""){
            alert(t("Please input an email"));
            return;
        }
        changeIsSent(true);
        const email = emailRef.current.value;

        const pkg = {
            checklistId: checklistId,
            email: email
        }

        fetch("/api/sendEmail", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not send email"));
        })
        .then(data => {
            changeIsSent(true);
        })
        .catch(error => alert(error))
    }

    const duplicateChecklist = function(){
        const token = localStorage.getItem("token");
        const userType = localStorage.getItem("accountType");
        const count = localStorage.getItem("totalChecklists");

        if(userType === "Lite"){
            if(count > 1){
                alert("Checklist limited reached, please upgrade account or delete a checklist to proceed")
                return;
            }
        }else if(userType === "Plus"){
            if(count > 14){
                alert("Checklist limited reached, please upgrade account or delete a checklist to proceed")
                return;
            }
        }

        let checklistName = checklistData.name;
        let str = checklistName;
        let matches = str.match(/\d+$/);
        if(matches){
            let newNumber = parseInt(matches[0]) + 1;
            checklistName = checklistName.substring(0, checklistName.lastIndexOf(matches[0])) + newNumber;
        }
        if(matches === null){
            checklistName += " 2";
        }

        const pkg = {
            data: {
                pre: checklistData.pre,
                post: checklistData.post
            },
            name: checklistName,
            surgeryProcedure: checklistData.surgeryProcedure
        }

        fetch("/api/checklist", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else response.json().then(res => {
                alert(res.message);
            });
        })
        .then(data => {
            if(!data) return;
            const count = parseInt(localStorage.getItem("totalChecklists"));
            localStorage.setItem('totalChecklists', count + 1);
            alert(t("Successfully duplicated checklist"));
            navigate("/dashboard");
        })
        .catch(error => alert(error))
    }

    function addRef(){
        const token = localStorage.getItem("token");
        const note = noteRef.current.value;

        const pkg = {
            id: checklistId,
            ref: note
        }

        fetch("/api/changeHistoryReference", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not add note"));
        })
        .then(data => {
            changeNoteBtnText(t("Saved"));
        })
        .catch(error => alert(error))
    }


    const isShown = function(){
        if(isEmailInput) return {display: "block"};
        return {display: "none"};
    }

    const generateTimeElement = function(timeFrame, timeType){
        if(timeFrame === 1) return timeFrame + " " + t(timeType);
        return timeFrame + " " + t(timeType) + "s";
    }

    const generateWhatsAppMessage = function(){
        const msg = t('Your POGA code is') + checklistCode + t("Please download");
        return "whatsapp://send?text=" + msg;
    }

    const canViewEmail = function(){
        const userType = localStorage.getItem("accountType");
        if(userType === "Lite"){
            return false;
        }
        return true;
    }

    return (
        <div className="full-checklist-container">
            <div className="full-checklist-header">
                <div className="full-checklist-header-title">
                    <span className="full-checklist-subtitle"> {checklistData.name} </span>
                    <span className="full-checklist-generate-code"> <button onClick={() => createCode()}> {t("Generate Code")} </button> </span>
                </div>

                <div className="full-checklist-subtitle" style={{fontSize:"20px"}}> {checklistData.surgeryProcedure} </div>

                <div className="full-checklist-header-actions"> 
                    <span onClick={() => {navigate("/dashboard")}} className="full-checklist-back-action">&#x2190; {t("Back")} </span>
                    <span className="full-checklist-action-toggle"> 

                    <Dropdown 
                        data={[
                            {
                                text: t("Delete"),
                                callBack: deleteChecklist
                            },
                            {
                                text: t("Edit"),
                                callBack: modifyChecklist
                            },
                            {
                                text: t("Duplicate"),
                                callBack: duplicateChecklist
                            }
                        ]} 
                        dropText={t("Actions")}
                        isSettings={true}
                    />

                    </span>
                </div>
            </div>

            <div className="full-checklist-main-content">
                <div className="full-checklist-tabs">
                    <span className="tab-item" id="full-checklist-pre-tab" onClick={ () => setShown(true) } style={styleTab(true)} > {t("Pre-Operative Checklist")} </span>
                    <span className="tab-space"> </span>
                    <span className="tab-item" id="full-checklist-post-tab" onClick={ () => setShown(false)} style={styleTab(false)} > {t("Post-Operative Checklist")} </span>
                </div>


                <div className="full-checklist-all-data">
                    <div id="full-checklist-pre-data" style={showTabStyle(true)}>
                        {checklistData.pre.map((item, index) => (
                            <div className="checklist-item-container" key={uuid()}>
                                <div className="item-timeframe" >
                                    {generateTimeElement(item.timeFrame, item.type)}
                                </div>
                                <div>

                                <div className="item-data">
                                    {
                                        item.tasks.map(
                                            (itemRow, idx) => (
                                                <FullChecklistItem type="pre" parentIndex={index} childKey={idx} setAvailability={changedAvailability} listItem={itemRow} key={uuid()}/>
                                            )
                                        )
                                    }
                                </div>


                                </div>
                            </div>
                        ))}

                    </div>

                    <div id="full-checklist-post-data" style={showTabStyle(false)}>
                        {checklistData.post.map((item, index) => (
                            <div className="checklist-item-container"  key={uuid()}>
                                <div className="item-timeframe">
                                    {generateTimeElement(item.timeFrame, item.type)}
                                </div>

                                <div className="item-data">
                                    {
                                        item.tasks.map(
                                            (itemRow, rowIndex) => (
                                                <FullChecklistItem type="post" parentIndex={index} childKey={rowIndex} listItem={itemRow} setAvailability={changedAvailability} key={uuid()}/>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        ))}
                    </div>

                    
                </div>

                <a style={{textDecoration:"none"}}> 
                <div onClick={() => navigateModify()} className="full-checklist-add-time-section">
                    <div id="full-checklist-post-data" >
                        <AiOutlineEdit/> <span className="add-time-button" >{t("Edit")}</span>
                    </div>
                </div>
                </a>

            </div>


            <Modal closeTimeoutMS={500} isOpen={showModal} style={customStyles} contentLabel="Minimal Modal Example">
                <div className="modal-header"> <span> {t("Your POGA Code")} </span> <span className="close-button" onClick={ modalHandler }><AiOutlineClose/></span> </div>
                <div className="checklist-modal-box">
                     <div className="checklist-subheader">
                        <div className="sub-header-1">{checklistData.name}</div> 
                        <div>{checklistData.surgeryProcedure}</div>
                     </div>

                     <div className="qr-code-box"> <QRCode className="qr-code-image" size={150} value={checklistCode} /> </div>

                    <div className="generated-code-input">
                     <div className="code-output-box"> 
                     {t("POGA Code")}
                            <div>
                                <input readOnly="readonly" style={{padding: "15px", fontWeight: "bold"}} value={checklistCode}></input>
                                <button className="modal-copy-btn" onClick={() => {navigator.clipboard.writeText(checklistCode); changeCopyBtnText("Copied!")}}>{copyBtnText}</button>
                            </div> 
                     </div>

                     <div className="code-output-box">
                        <input maxLength={64} placeholder={t("Reference Note")} className="checklist-modal-input" ref={noteRef} style={{padding: "15px"}} ></input>
                        <button className="checklist-modal-save-ref" onClick={() => addRef()}>{noteBtnText}</button>
                     </div>

                     <div className="code-utility-buttons"> 
                        <a style={{display:"none"}} href="/pdfView" target="_blank"><button>Print</button></a>
                        <button style={{display:"none"}}>{t("Email")}</button>
                        <a style={{display:"none"}} href="/checklist/123"><button>{t("View")}</button></a>
                        <a href={generateWhatsAppMessage()}><button style={{border: "1px solid green", color:"green"}}>WhatsApp</button></a>
                        {canViewEmail() && <button onClick={() => changeEmailInput(true)} style={{border: "1px solid blue", color:"blue"}}>{t("Email")}</button>}
                     </div>

                     <div className="send-email-container" style={isShown()}>
                         {t("Email")}: <input placeholder="user@email.com" ref={emailRef} maxLength="50" className="send-email-input"/>{isSent ? <button>{t("Sent")}!</button> : <button onClick={() => sendEmail()}>{t("Send")}</button>}
                     </div>
                    </div>
                </div>

            </Modal>

            {/* <PDFViewer>
                <MyDocument />
            </PDFViewer> */}
        </div>
    )
}
