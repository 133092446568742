import React, { useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './Login.css';

export default function Login(props){

    const emailRef = useRef();
    const passwordRef = useRef();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    //const { login } = useAuth();
    //const test = useContext(AuthContext);

    useEffect( () => {
        var userLanguage = window.navigator.userLanguage || window.navigator.language;
        i18n.changeLanguage(localStorage.getItem(userLanguage));
    },[])

    const login = function(email, password){
        const data = {
            "email": email,
            "password": password
        };
    
        fetch("/api/auth/signin", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(response.ok) return response.json();
            else response.json().then(msg => {
                alert(msg.message);
            });
        })
        .then(data => {
          if(!data) return;
          let tokenTime = new Date().getTime() / 1000;
          localStorage.setItem('token', data.accessToken);
          localStorage.setItem('name', data.name);
          localStorage.setItem('tokenTime', tokenTime.toString());
          localStorage.setItem('language', data.language);
          localStorage.setItem('totalChecklists', data.totalChecklists);
          localStorage.setItem('accountType', data.accountType);
          navigate("/dashboard");
        })
        .catch(error => console.log(error))
    }

    function getInputs(){
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        login(email, password);
    }

    return (
            <div className="login-container">

                    <img className='logo' src="/logo.png" alt="Poga logo" />

                    <div className="login-box">

                        <div className='login-inputs'>
                            <div className="login-main-text">{t("Welcome to POGA")}!</div>
                            <div className="login-sub-text">{t("Please login to your account")}</div>

                            <div>
                                <div className="login-input-text">
                                {t("Email")}
                                </div>
                                <input maxLength="50" name="username" ref={emailRef} className="credentials-input"/>
                            </div>

                            <div style={{marginTop:"15px"}}>
                                <div className="login-input-text">
                                {t("Password")}
                                </div>
                                <input maxLength="50" name="password" onKeyDown={e => e.key === 'Enter' && getInputs()} ref={passwordRef} type="password" className="credentials-input"/>
                            </div>

                            <div className="sub-inputs">
                                <span className='forgot-password'> <a href="/forgotpassword" target="_blank"> Forgot password? </a></span>
                                <span style={{display:"none"}} className='checkbox'>
                                    <input type="checkbox"></input>Remember me
                                </span>
                            </div>

                            <br/>
                            <div>
                                <button className="login-button" onClick={() => getInputs()} >  {t("Login")}  </button>
                            </div>

                            <h2 className="divider"><span>{t("or")}</span></h2>

                            <div>{t("Interested in using our platform")}</div>
                            <a href="https://app.pogamed.com/signup/trial" target="_blank"><button className="signup-button"> {t("Request an account")} </button></a>

                        </div>
                    </div>

                    <div className="footer loginFooter">
                        ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
                    </div>
            </div>
    )
}


