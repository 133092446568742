import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal';
import { useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { BiCog } from 'react-icons/bi';
import './Checklist.css';

export default function Signup(props) {
    const { t } = useTranslation();
    let { id } = useParams();
    const navigate = useNavigate();
    const tosAgreement = useRef();
    const [tosChecked, setTosChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [captchaCode, setCaptchaCode] = useState('');
    const [registerInfo, setRegisterInfo] = useState({
        "email": "",
        "password": "",
        "confPassword": "",
        "firstName": "",
        "lastName": "",
        "practiceName": "",
        "practiceType": "",
        "phone": "",
        "country": "",
        "city": "",
        "address": ""
    });
    const [errorInfo, setErrorInfo] = useState({
        "email": "",
        "password": "",
        "confPassword": "",
        "firstName": "",
        "lastName": "",
        "practiceName": "",
        "practiceType": "",
        "phone": "",
        "country": "",
        "city": "",
        "tos": "",
        "captcha": ""
    });
    const customStyles = {
        content: {
            width: '85%',
            minWidth: "300px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: "none",
            borderRadius: "5px"
        },
    };

    const captchaChange = function(code){
        setCaptchaCode(code);
    }


    const isValidPassword = function(password){
        var errors = [];
        var lengthExpression = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        var symbolExpression = /[!@#$%^&*]/;
        var numberExpression = /\d/;
        var uppercaseExpression = /[A-Z]/;
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        if(!lengthExpression.test(password)) errors.push("Password must be between 6 and 16 characters long");
        if(!symbolExpression.test(password)) errors.push("Password must contain a special character");
        if(!numberExpression.test(password)) errors.push("Password must contain a number");
        if(!uppercaseExpression.test(password)) errors.push("Password must contain an uppercase letter");

        return errors;
    }

    const isValidEmail = function(email){
        var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
        return pattern.test(email);
    }

    const register = function(){
        const errorHolder = {};
        let passwordErrors = isValidPassword(registerInfo.password);

        if(!isValidEmail(registerInfo.email)) errorHolder.email = "Email is invalid, please input a valid email"
        if(passwordErrors.length > 0){
            const pwdErrors = passwordErrors.reduce((prev, curr) => prev + curr + "\n", "");
            errorHolder.password = pwdErrors;
        }

        if(registerInfo.password === "") errorHolder.password = "Please input a valid password";
        if(registerInfo.confPassword === "") errorHolder.confPassword = "Passwords do not match";
        if(registerInfo.firstName === "") errorHolder.name = "Please input a valid firstName";
        if(registerInfo.lastName === "") errorHolder.name = "Please input a valid lastName";
        if(registerInfo.practiceName === "") errorHolder.practiceName = "Please input a valid practice name";
        if(registerInfo.practiceType === "") errorHolder.practiceType = "Please input a valid practice type";
        if(registerInfo.phone === "") errorHolder.phone = "Please input a valid phone number";
        if(registerInfo.country === "") errorHolder.country = "Please input a valid country";
        if(registerInfo.city === "") errorHolder.city = "Please input a valid city name";
        if(!tosChecked) errorHolder.tos = "Please agree to Terms of Usage";
        if(captchaCode === '') errorHolder.captcha = "Please complete Captcha";

        if(Object.keys(errorHolder).length > 0) setErrorInfo(errorHolder);
        else postNewValues();
    }

    const manageChange = function(name, value){
        const holder = registerInfo;
        holder[name] = value;

        setRegisterInfo(holder);
    }

    const checkMatchingPassword = function(value){
        const errorHolder = {...errorInfo};
        if(value !== registerInfo.password){
            errorHolder.confPassword = "Passwords do not match";
        }else{
            errorHolder.confPassword = "";
        }

        setErrorInfo(errorHolder);
    }

    const checkValidPassword = function(value){
        let errorHolder = {...errorInfo};
        let errors = isValidPassword(value);
        let errorMsg = "";

        if(errors.length > 0){
            errors.map(err => {errorMsg = errorMsg + err + "\n"});
            errorHolder.password = errorMsg;
        }else{
            errorHolder.password = "";
        }

        setErrorInfo(errorHolder);
    }

    const checkValidEmail = function(value){
        let errorHolder = {...errorInfo};
        let errorMsg = "";
        if(!isValidEmail(value)) errorMsg = "Email is invalid, please input a valid email"

        errorHolder.email = errorMsg;
        setErrorInfo(errorHolder);
    }

    const postNewValues = function(){
        let postPackage  = {
            regInfo: registerInfo,
            signupKey: id,
            captcha: captchaCode
        }
        fetch("/api/register", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postPackage)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            alert(data.message);
            if(data.success) {
                navigate("/login");
            }
        })
        .catch(error => alert(error))
    }

    return (
        <div className="full-signup-container">
        <header className="signup-header">
            <img className='logo' src="/logo.png" alt="Poga logo" />
        </header>

        <main className="signup-container">
            <div className="signup-area">
                <section className="signup-header">
                    <h3>Welcome to Poga!</h3>
                </section>

                <span>
                Please enter all information below to register
                </span>

                <div id="email-register">
                    <span>
                    <label>Email</label><label className="required-text"> *</label>
                        <br/>
                        <input 
                            onChange={el => manageChange("email", el.target.value)}
                            onBlur={el => checkValidEmail(el.target.value)}
                            placeholder="example@example.com"
                            maxLength={64}
                        />
                        {errorInfo.email !== "" && <div className="error-text">{errorInfo.email}</div>}
                    </span>
                </div>

                <div>
                    <span>
                        <label>Password</label><label className="required-text"> *</label>
                        <br/>
                        <input type="password" 
                            onBlur={el => checkValidPassword(el.target.value)} 
                            onChange={el => manageChange("password", el.target.value)}
                            maxLength={64}
                        />
                        {errorInfo.password !== "" && <div className="error-text">{errorInfo.password}</div>}
                    </span>

                    <span>
                        <label>Confirm Password</label><label className="required-text"> *</label>
                        <br/>
                        <input type="password" 
                            onBlur={el => checkMatchingPassword(el.target.value)} 
                            onChange={el => manageChange("confPassword", el.target.value)}
                            maxLength={64}
                        />
                        {errorInfo.confPassword !== "" && <div className="error-text">{errorInfo.confPassword}</div>}
                    </span>
                </div>

                <div>
                    <span>
                        <label>First Name</label><label className="required-text"> *</label>
                        <br/>
                        <input onChange={el => manageChange("firstName", el.target.value)} placeholder="First Name" maxLength={64}/>
                        {errorInfo.name !== "" && <div className="error-text">{errorInfo.name}</div>}
                    </span>

                    <span>
                        <label>Last Name</label><label className="required-text"> *</label>
                        <br/>
                        <input onChange={el => manageChange("lastName", el.target.value)} placeholder="Last Name" maxLength={64}/>
                        {errorInfo.name !== "" && <div className="error-text">{errorInfo.name}</div>}
                    </span>
                </div>

                <div>
                    <span>
                        <label>Practice Name</label><label className="required-text"> *</label>
                        <br/>
                        <input onChange={el => manageChange("practiceName", el.target.value)} placeholder="Practice Name" maxLength={64}/>
                        {errorInfo.practiceName !== "" && <div className="error-text">{errorInfo.practiceName}</div>}
                    </span>

                    <span>
                        <label>Type of Practice</label><label className="required-text"> *</label>
                        <br/>
                        <input onChange={el => manageChange("practiceType", el.target.value)} placeholder="Practice Type" maxLength={64}/>
                        {errorInfo.practiceType !== "" && <div className="error-text">{errorInfo.practiceType}</div>}
                    </span>
                </div>

                <div>
                    <span>
                        <label>Phone Number</label><label className="required-text"> *</label>
                        <br/>
                        <input onChange={el => manageChange("phone", el.target.value)} placeholder="(012)-345-6789" maxLength={16}/>
                        {errorInfo.phone !== "" && <div className="error-text">{errorInfo.phone}</div>}
                    </span>

                    <span>
                        <label>Street</label>
                        <br/>
                        <input onChange={el => manageChange("address", el.target.value)} maxLength={64}/>
                        {errorInfo.address !== "" && <div className="error-text">{errorInfo.address}</div>}
                    </span>
                </div>

                <div>
                    <span>
                        <label>City</label><label className="required-text"> *</label>
                        <br/>
                        <input onChange={el => manageChange("city", el.target.value)} maxLength={64}/>
                        {errorInfo.city !== "" && <div className="error-text">{errorInfo.city}</div>}
                    </span>

                    <span>
                        <label>Country</label><label className="required-text"> *</label>
                        <br/>
                        <input onChange={el => manageChange("country", el.target.value)} maxLength={64}/>
                        {errorInfo.country !== "" && <div className="error-text">{errorInfo.country}</div>}
                    </span>
                </div>

                <span> 
                    <input onClick={() => setTosChecked(oldvalue => !oldvalue)} ref={tosAgreement} id="tos-input" type="checkbox" maxLength={64}/> 
                    I Agree to <label onClick={() => setShowModal(oldVal => !oldVal)} className="tos-popup">Terms of Use</label>
                    {!errorInfo.tos !== "" && <div className="error-text tos-error">{errorInfo.tos}</div>}
                </span>

                <ReCAPTCHA
                    sitekey="6LfCzM8iAAAAAHmTE5GrBlVEsTvCOkDTVuU64vwM"
                    onChange={captchaChange}
                />
                {errorInfo.captcha !== "" && <div className="error-text">{errorInfo.captcha}</div>}

                <div className="btn-container">
                    <button onClick={() => register()}>Request Account</button>
                </div>
            </div>
        </main>

        
        <div className="footer loginFooter">
            ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
        </div>



        <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={customStyles} contentLabel="TOS">
            <div className="tos-modal-box">
                <iframe src="https://pogamed.com/home/terms-of-use/poga-dashboard/" title="Terms of Use"></iframe>
            </div>
        </Modal>
        </div>
    )
}