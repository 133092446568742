import Login from './containers/Login';
import Dashboard from './containers/Dashboard'
import UserSettings from './containers/UserSettings'
import Checklist from './containers/ChecklistContainer'
import ChecklistHistory from './containers/HistoryContainer'
import Signup from './containers/SignupContainer'
import ForgotPassword from './containers/ForgotPasswordContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import PdfView from './containers/PdfView'
import NewChecklistContainer from './containers/NewChecklistContainer';
import VerifyContainer from './containers/VerifyContainer';
import { Routes, Route } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';



function App() {

  return (
    
    <div className="App">

      <Routes>
        <Route path="/" exact element={<Login />}/>
        <Route path="/login" exact element={<Login/>}/>
        <Route path="/signup/:id" exact element={<Signup/>}/>
        <Route path="/forgotpassword" exact element={<ForgotPassword/>}/>
        <Route path="/verify/:id/:code" exact element={<VerifyContainer/>}/>
        <Route path="/reset/:code" exact element={<ResetPasswordContainer/>}/>
        <Route path="/dashboard" exact element={<RequireAuth> <Dashboard/> </RequireAuth>}/>
        <Route path="/settings" exact element={<RequireAuth> <UserSettings/> </RequireAuth>}/>
        <Route path="/pdfView" exact element={<RequireAuth><PdfView/></RequireAuth>}/>
        <Route path="/checklist/:id" exact element={<RequireAuth><Checklist/></RequireAuth>}/>
        <Route path="/newchecklist" exact element={<RequireAuth><NewChecklistContainer/></RequireAuth>}/>
        <Route path="/history" exact element={<RequireAuth><ChecklistHistory/></RequireAuth>}/>
        <Route path="/modifychecklist/:id/:showTab" exact element={<RequireAuth><NewChecklistContainer/></RequireAuth>}/>
      </Routes>

    </div>
  );
}

export default App;
