import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FcCheckmark } from 'react-icons/fc';
import { AiOutlineClose } from 'react-icons/ai';
import { RiFileInfoLine } from 'react-icons/ri';
import Modal from 'react-modal';

export default function FullChecklistItem(props) {
    const [isActive, setActive] = useState(props.listItem.enabled)
    const {listItem, setAvailability, childKey, type, parentIndex} = props;
    const [showModal, changeModal] = useState(false);
    const [showInfo, changeShowInfo] = useState("hidden")
    const { t } = useTranslation();
    const additionalinfoRef = useRef();
    const isInitialMount = useRef(true);

    const modalStyle = {
        content: {
            width: '45%',
            minWidth: "300px",
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            borderRadius: '9px'
        },
    };

    useEffect(
        () => {
            if(isInitialMount.current) isInitialMount.current = false;
            else setAvailability(type, parentIndex, childKey, isActive);
        }, [isActive, listItem.data, childKey, setAvailability, parentIndex, type]
    );

    useEffect( () => {
        if(listItem.additionalInfo !== undefined && listItem.additionalInfo !== "") changeShowInfo("visible");
    }, [listItem.additionalInfo])

    const openAdditionalInfoModal = function(){
        changeModal(true);
    }

    function generateHtml(){
        if( isActive ) return <FcCheckmark  onClick={changeInUse} style={{position: "relative", paddingLeft: '5px'}}/>
        return <AiOutlineClose onClick={changeInUse} style={{color: "red", position: "relative", paddingLeft: '5px'}} />
    }

    function changeInUse(){
        setActive( prevValue => !prevValue);
    }

    function getStyle(){
        if(isActive) return {};
        return {textDecoration: "line-through", textDecorationColor: "red"};
    }

    //test.filter(item => item.id !== id)

    return (
        <>
            <div className="item-data">
                <span className="selected-icon" >
                    <RiFileInfoLine style={{color: "#4b3e8a", visibility:showInfo}} className="trashBtnModify" onClick={() => openAdditionalInfoModal()} />
                    {generateHtml()} 
                </span>
                <span onClick={changeInUse} className="task-description" style={getStyle()}> {listItem.data} </span>
            </div>

            <Modal onRequestClose={() => changeModal(false)} isOpen={showModal} style={modalStyle} contentLabel="Minimal Modal Example">
                <div className="text-modal-box">
                    <div className="text-modal-header">{t("Additional Information")}</div>
                    <textarea disabled ref={additionalinfoRef} maxLength='750' rows="8" className="text-modal-main-text" defaultValue={listItem.additionalInfo}></textarea>
                    <button onClick={() => changeModal(false)} className="text-modal-btn">{t("Okay")}</button>
                </div>
            </Modal>
        </>
    )
}
