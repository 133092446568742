import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'

export default function ResetPasswordContainer(props) {
    const { t } = useTranslation();
    let { code } = useParams();
    const navigate = useNavigate();
    const [captchaCode, setCaptchaCode] = useState('');
    const [recoveryInfo, setRecoveryInfo] = useState({
        "password": "",
        "confPassword": ""
    });
    const [errorInfo, setErrorInfo] = useState({
        "password": "",
        "confPassword": ""
    });

    const captchaChange = function(code){
        setCaptchaCode(code);
    }

    const register = function(){
        const errorHolder = {};

        if(recoveryInfo.password === "") errorHolder.password = "Please input a valid password";
        if(recoveryInfo.confPassword === "") errorHolder.confPassword = "Passwords do not match";
        if(captchaCode === '') errorHolder.captcha = "Please complete Captcha";

        if(Object.keys(errorHolder).length > 0) setErrorInfo(errorHolder);
        else postNewValues();
    }

    const manageChange = function(name, value){
        const holder = recoveryInfo;
        holder[name] = value;

        setRecoveryInfo(holder);
    }

    const checkMatchingPassword = function(value){
        const errorHolder = {...errorInfo};
        if(value !== recoveryInfo.password){
            errorHolder.confPassword = "Passwords do not match";
        }else{
            errorHolder.confPassword = "";
        }

        setErrorInfo(errorHolder);
    }

    const isValidPassword = function(password){
        var errors = [];
        var lengthExpression = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        var symbolExpression = /[!@#$%^&*]/;
        var numberExpression = /\d/;
        var uppercaseExpression = /[A-Z]/;
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        if(!lengthExpression.test(password)) errors.push("Password must be between 6 and 16 characters long");
        if(!symbolExpression.test(password)) errors.push("Password must contain a special character");
        if(!numberExpression.test(password)) errors.push("Password must contain a number");
        if(!uppercaseExpression.test(password)) errors.push("Password must contain an uppercase letter");

        return errors;
    }

    const checkValidPassword = function(value){
        let errorHolder = {...errorInfo};
        let errors = isValidPassword(value);
        let errorMsg = "";

        if(errors.length > 0){
            errors.map(err => {errorMsg = errorMsg + err + "\n"});
            errorHolder.password = errorMsg;
        }else{
            errorHolder.password = "";
        }

        setErrorInfo(errorHolder);
    }

    const postNewValues = function(){
        let postPackage  = {
            resetInfo: recoveryInfo,
            captcha: captchaCode,
            code: code
        }
        fetch("/api/reset", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postPackage)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            console.log("Caught response");
            if(data.success) {
                alert("Password successfully changed");
                navigate("/login");
            }else{
                alert(data.message);
            }
        })
        .catch(error => {
            alert(error);
        })
    }

    return (
        <div className="full-signup-container">
        <header className="signup-header">
            <img className='logo' src="/logo.png" alt="Poga logo" />
        </header>

        <main className="signup-container">
            <div className="signup-area">
                <section className="signup-header">
                    <h3>Account Recovery</h3>
                </section>

                <span>
                Please set a new password for your account
                </span>

                <div id="email-register">
                    <span>
                    <label>Password</label><label className="required-text"> *</label>
                        <br/>
                        <input type="password" 
                            onBlur={el => checkValidPassword(el.target.value)} 
                            onChange={el => manageChange("password", el.target.value)}
                            maxLength={64}
                        />
                        {errorInfo.password !== "" && <div className="error-text-recovery">{errorInfo.password}</div>}
                    </span>
                </div>

                <div id="email-register">
                    <span>
                    <label>Confirm Password</label><label className="required-text"> *</label>
                        <br/>
                        <input type="password" 
                            onBlur={el => checkMatchingPassword(el.target.value)} 
                            onChange={el => manageChange("confPassword", el.target.value)}
                            maxLength={64}
                        />
                        {errorInfo.confPassword !== "" && <div className="error-text-recovery">{errorInfo.confPassword}</div>}
                    </span>
                </div>

                <ReCAPTCHA
                    sitekey="6LfCzM8iAAAAAHmTE5GrBlVEsTvCOkDTVuU64vwM"
                    onChange={captchaChange}
                />
                {errorInfo.captcha !== "" && <div className="error-text">{errorInfo.captcha}</div>}

                <div className="btn-container">
                    <button onClick={() => register()}>Change Password</button>
                </div>
            </div>
        </main>

        
        <div className="footer loginFooter">
            ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
        </div>
        </div>
    )
}