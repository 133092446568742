import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillGridFill } from 'react-icons/bs';
import NavDropdown from './NavDropdown';
import { useNavigate } from "react-router-dom";
import { appsIcon } from '../assets/IconConstants';
import './Navbar.css';

export default function Navbar(){
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goDashboard = function(){
        navigate("/dashboard");
    }

        return (
            <div className="top-nav">
                <ul>
                    <li className="top-nav-logo">
                        <img onClick={() => goDashboard()} className='nav-logo' src="/logoNav.png" alt="Poga Logo" />
                    </li>
                    <li onClick={() => goDashboard()}>
                        <span className="nav-item">{appsIcon} {t('DASHBOARD')}</span>
                    </li>
                    <li className="right-tab notification">
                    <NavDropdown/>
                    </li>
                </ul>
            </div>
        )
}

{/* <li className="right-tab notification"><a href="/dashboard"><IoMdNotificationsOutline /></a></li>
<li className="right-tab"><b ><input placeholder=" Search" className="nav-search"/></b></li> */}