import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import './Checklist.css';

export default function ForgotPassword(props) {
    const { t } = useTranslation();
    let { id } = useParams();
    const navigate = useNavigate();
    const [captchaCode, setCaptchaCode] = useState('');
    const [recoveryInfo, setRecoveryInfo] = useState({
        "email": ""
    });
    const [errorInfo, setErrorInfo] = useState({
        "email": ""
    });

    const captchaChange = function(code){
        setCaptchaCode(code);
    }

    const isValidEmail = function(email){
        var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
        return pattern.test(email);
    }

    const register = function(){
        const errorHolder = {};

        if(!isValidEmail(recoveryInfo.email)) errorHolder.email = "Email is invalid, please input a valid email"
        //if(captchaCode === '') errorHolder.captcha = "Please complete Captcha";

        if(Object.keys(errorHolder).length > 0) setErrorInfo(errorHolder);
        else postNewValues();
    }

    const manageChange = function(name, value){
        const holder = recoveryInfo;
        holder[name] = value;

        setRecoveryInfo(holder);
    }

    const checkValidEmail = function(value){
        let errorHolder = {...errorInfo};
        let errorMsg = "";
        if(!isValidEmail(value)) errorMsg = "Email is invalid, please input a valid email"

        errorHolder.email = errorMsg;
        setErrorInfo(errorHolder);
    }

    const postNewValues = function(){
        let postPackage  = {
            regInfo: recoveryInfo,
            captcha: captchaCode
        }
        fetch("/api/recover", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postPackage)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if(data.success) {
                alert("Success, recovery instructions will be sent to email if it exists.");
                navigate("/login");
            }else{
                alert(data.message);
            }
        })
        .catch(error => {
            console.log(error);
            navigate("/login");
        })
    }

    return (
        <div className="full-signup-container">
        <header className="signup-header">
            <img className='logo' src="/logo.png" alt="Poga logo" />
        </header>

        <main className="signup-container">
            <div className="signup-area">
                <section className="signup-header">
                    <h3>Account Recovery</h3>
                </section>

                <span>
                Please input account email and wait for recovery details to be sent
                </span>

                <div id="email-register">
                    <span>
                    <label>Email</label><label className="required-text"> *</label>
                        <br/>
                        <input 
                            onChange={el => manageChange("email", el.target.value)}
                            onBlur={el => checkValidEmail(el.target.value)}
                            placeholder="example@example.com"
                            maxLength={64}
                        />
                        {errorInfo.email !== "" && <div className="error-text-recovery">{errorInfo.email}</div>}
                    </span>
                </div>

                <ReCAPTCHA
                    sitekey="6LfCzM8iAAAAAHmTE5GrBlVEsTvCOkDTVuU64vwM"
                    onChange={captchaChange}
                />
                {errorInfo.captcha !== "" && <div className="error-text">{errorInfo.captcha}</div>}

                <div className="btn-container">
                    <button onClick={() => register()}>Request New Password</button>
                </div>
            </div>
        </main>

        
        <div className="footer loginFooter">
            ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
        </div>
        </div>
    )
}