import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { BiBook } from 'react-icons/bi';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { AiOutlineHistory } from 'react-icons/ai';
import { useTranslation } from 'react-i18next'
import './Checklist.css';
import uuid from 'react-uuid'
import ChecklistItem from '../components/ChecklistItem'
import ReactTooltip from 'react-tooltip';
import { plusIcon, bookIcon, archiveIcon, sortDownIcon, sortUpIcon } from '../assets/IconConstants';

export default function Checklist(props) {
    const [checkListItem, setChecklistData] = useState(props.checklistData);
    const [sortConfig, setSortConfig] = useState("name");
    const navigate = useNavigate();
    const { t } = useTranslation();

    checkListItem.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    const filterTable = function(e){
        const searchVal = e.target.value.toLowerCase();
        setChecklistData(props.checklistData.filter(item => item.name.toLowerCase().includes(searchVal) || item.surgeryProcedure.toLowerCase().includes(searchVal)));
    }

    const toolTipInfo = function(){
        const userType = localStorage.getItem("accountType");
        const count = localStorage.getItem("totalChecklists");
        let msg = 'Allowed Checklists: ' + count + '/';

        if(userType === "Lite"){
            msg += '2';
        }else if(userType === "Plus"){
            msg += '15';
        }else{
            msg += 'Unlimited'
        }

        return msg;
    }

    const getArrow = function(type) {
        if(sortConfig.direction === 'ascending' && sortConfig.key === type) return sortDownIcon;

        return sortUpIcon;
    }

    const canAddChecklist = function(){
        const userType = localStorage.getItem("accountType");
        const count = localStorage.getItem("totalChecklists");

        if(userType === "Lite"){
            if(count > 1){
                return false
            }
        }else if(userType === "Plus"){
            if(count > 14){
                return false;
            }
        }

        return true;
    }

    const canViewHistory = function(){
        const userType = localStorage.getItem("accountType");

        if(userType === "Lite"){
            return false;
        }

        return true;
    }

    useEffect(() => {
        setChecklistData(props.checklistData);
    }, [props.checklistData])

    
    return (
        <div className="checklist-container">
            <ReactTooltip type="info" place="top" padding="10px" />
            <div className="checklist-header">
            
                <span className="widget-name"> {bookIcon} <span>{t('Notification Checklists')}</span> </span>
                <span className="new-checklist-button">
                    {canViewHistory() ?
                    <span className="checklist-history-button" onClick={ () => navigate("/history")}>
                        {archiveIcon}  {t('Code History')}
                    </span>
                    :
                    <span data-tip="Upgrade to view history and analytics" className="checklist-history-button">
                        {archiveIcon}  {t('Code History')}
                    </span>
                    }
                    {canAddChecklist() ? <button data-tip={toolTipInfo()} onClick={ () => navigate("/newchecklist")}>{t('Add Checklist')}{plusIcon}</button> : <button data-tip={toolTipInfo()} onClick={() => alert("Checklist limited reached, please upgrade account or delete a checklist to proceed")}>{t('Add Checklist')}{plusIcon}</button>}
                </span>
                <div className="search-input">
                    <input placeholder={t('Search')} onChange={(e) => filterTable(e)}></input>
                </div>
            </div>

            <div className="table-wrapper">
                <table className="checklist-table">
                    <tbody>
                        <tr>
                            <th onClick={() => {requestSort("name")}}>{t('Name')} <span className="down-icon-container"> {getArrow("name")} </span></th>
                            <th onClick={() => {requestSort("surgeryProcedure")}}>{t('Procedure')} {getArrow("surgeryProcedure")}</th>
                            <th>{t('Code')}</th>
                        </tr>

                        {checkListItem.map((item) => (
                            item.isEnabled && <ChecklistItem name={item.name} surgeryProcedure={item.surgeryProcedure} code="5as4d65as4d6" key={uuid()} checklistId={item.id}/>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}