import React from 'react'
import FullChecklist from '../components/FullChecklist'
import Signup from '../components/Signup'
import '../components/FullChecklist.css'


export default function SignupContainer() {

    return (
        <>
            <Signup />
        </>
    )
}
