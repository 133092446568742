import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { RiFileAddLine } from 'react-icons/ri';
import { AiOutlineClose, AiOutlineWhatsApp } from 'react-icons/ai';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next'
import { taskAddIcon } from '../assets/IconConstants';
var QRCode = require('qrcode.react');
Modal.setAppElement('#root');


function ChecklistItem(props) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const emailRef = useRef();
    const noteRef = useRef();
    const [showModal, handleModal] = useState(false);
    const [checklistCode, changeChecklistCode] = useState("");
    const [copyBtnText, changeCopyBtnText] = useState("Copy");
    const [noteBtnText, changeNoteBtnText] = useState(t("Add Note"));
    const [isEmailInput, changeEmailInput] = useState(false);
    const [isSent, changeIsSent] = useState(false);
    const [checklistId, setChecklistId] = useState(null);

    const customStyles = {
        content: {
            width: '30%',
            minWidth: "300px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            borderRadius: '9px'
        },
    };

    function navigateToChecklist(){
        const navUrl = "/checklist/"+props.checklistId;
        navigate(navUrl);
    }

    function modalHandler(){
        handleModal( oldBool => !oldBool );
        changeNoteBtnText(t("Add Note"));
    }

    function createCode(){
        changeCopyBtnText(t("Copy"));
        const token = localStorage.getItem("token");
        const pkg = {
            checklistId: props.checklistId
        }

        fetch("/api/createAppChecklist", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not generate checklist code"));
        })
        .then(data => {
            changeChecklistCode(data.code);
            setChecklistId(data.id);
            handleModal( oldBool => !oldBool );
        })
        .catch(error => alert(error))
    }

    function addRef(){
        const token = localStorage.getItem("token");
        const note = noteRef.current.value;

        const pkg = {
            id: checklistId,
            ref: note
        }

        fetch("/api/changeHistoryReference", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not add note"));
        })
        .then(data => {
            changeNoteBtnText(t("Saved"));
        })
        .catch(error => alert(error))
    }

    function sendEmail(){
        const token = localStorage.getItem("token");
        if(emailRef.current.value === ""){
            alert(t("Please input an email"));
            return;
        }
        const email = emailRef.current.value;

        const pkg = {
            checklistId: checklistId,
            email: email
        }

        fetch("/api/sendEmail", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(t("Could not send email"));
        })
        .then(data => {
            changeIsSent(true);
        })
        .catch(error => alert(error))
    }

    const canViewEmail = function(){
        const userType = localStorage.getItem("accountType");
        if(userType === "Lite"){
            return false;
        }

        return true;
    }

    const generateWhatsAppMessage = function(){
        const msg = t('Your POGA code is') + checklistCode + t("Please download");
        return "whatsapp://send?text=" + msg;
    }

    const isShown = function(){
        if(isEmailInput) return {display: "block"};
        return {display: "none"};
    }

    return (
        <tr key={props.code} className="checklist-table-row">
            <td onClick={e => navigateToChecklist(e)} className="td-start">{props.name}</td>
            <td onClick={e => navigateToChecklist(e)} className="td-mid">{props.surgeryProcedure}</td>
            <td className="td-end"> 
                <span onClick={ e => createCode(e) }  className="checklist-code"> 
                    {taskAddIcon} {t("Generate Code")} 
                </span>
            </td>

            <Modal closeTimeoutMS={500} isOpen={showModal} style={customStyles} contentLabel="Minimal Modal Example">
                <div className="modal-header"> <span> {t("Your POGA Code")} </span> <span className="close-button" onClick={ modalHandler }><AiOutlineClose/></span> </div>
                <div className="checklist-modal-box">
                     <div className="checklist-subheader">
                        <div className="sub-header-1">{props.name}</div> 
                        <div>{props.surgeryProcedure}</div>
                     </div>

                     <div className="qr-code-box"> <QRCode className="qr-code-image" size={150} value={checklistCode} /> </div>

                    <div className="generated-code-input">
                     <div className="code-output-box"> 
                        {t("POGA Code")} 
                            <div>
                                <input readOnly="readonly" style={{padding: "15px", fontWeight: "bold"}} value={checklistCode}></input>
                                <button className="modal-copy-btn" onClick={() => {navigator.clipboard.writeText(checklistCode); changeCopyBtnText(t("Copied"))}}>{copyBtnText}</button>
                            </div> 
                     </div>

                     <div className="code-output-box">
                        <input maxLength={64} placeholder={t("Reference Note")} className="checklist-modal-input" ref={noteRef} style={{padding: "15px"}} ></input>
                        <button className="checklist-modal-save-ref" onClick={() => addRef()}>{noteBtnText}</button>
                     </div>

                     <div className="code-utility-buttons"> 
                        <button onClick={() => navigateToChecklist()}>{t("View")}</button>
                        <a href={generateWhatsAppMessage()}><button style={{border: "1px solid green", color:"green"}}>WhatsApp</button></a>
                        {canViewEmail() && <button onClick={() => changeEmailInput(true)} style={{border: "1px solid blue", color:"blue"}}>{t("Email")}</button>}
                     </div>
                     
                     <div className="send-email-container" style={isShown()}>
                     {t("Email")}: <input placeholder="user@email.com" ref={emailRef} maxLength="50" className="send-email-input"/>{isSent ? <button>{t("Sent")}!</button> : <button onClick={() => sendEmail()}>{t("Send")}</button>}
                     </div>
                    </div>
                </div>

            </Modal>
        </tr>
    )
}

export default ChecklistItem