import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Checklist from '../components/Checklist'
//import TimerWidget from '../components/TimerWidget'
//import EquipmentWidget from '../components/EquipmentWidget'
import './Dashboard.css';

export default function Dashboard() {

    let [userData, setUserdata] = useState([]);
    useEffect( () => {
        const token = localStorage.getItem("token");
        fetch("/api/providerChecklists",{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        .then(response => response.json())
        .then(data => {
            setUserdata(data);
        })
    },[])

    return (
        <div className="main-dashboard">
            <Navbar/>
            <div className="dashboard-container">
                <Checklist checklistData={userData}/>
            </div>
            <div className="footer loginFooter">
                ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
            </div>
        </div>
    )
}

{/* <TimerWidget/>
<EquipmentWidget/> */}

