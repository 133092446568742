import React from 'react'
import ForgotPassword from '../components/ForgotPassword'
import '../components/FullChecklist.css'


export default function ForgotPasswordContainer() {

    return (
        <>
            <ForgotPassword />
        </>
    )
}
