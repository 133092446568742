import React, { useState } from 'react'

import ReactDOMServer from 'react-dom/server';
import Html from 'react-pdf-html';
import { Page, Text, View, Document, StyleSheet, ReactPDF, PDFViewer } from '@react-pdf/renderer';
import QRImage from 'react-qr-image'




const mockData =
{
    "name": "Total Knee Replacement",
    "pre": [
        {
            "timeFrame": "6 months",
            "tasks": [
                {
                    "enabled": true,
                    "data": "No more injections to the knee of any kind (IE corticosteroids or hyaluronic acid/viscosupplementation) **"
                },
                {
                    "enabled": true,
                    "data": "Start pre-habilitation exercises (core strengthening, isometric leg strengthening, stationary bike if tolerated)"
                }
            ]
        },
        {
            "timeFrame": "1 day",
            "tasks": [
                {
                    "enabled": true,
                    "data": "Ensure that patient understands any/all instructions given to them regarding their medications and day of surgery (When to stop taking certain medications, especially regarding diabetic meds/anticoagulants)"
                },
                {
                    "enabled": false,
                    "data": "No food"
                },
                {
                    "enabled": true,
                    "data": "Report any new fevers"
                }
            ]
        }
    ],
    "post": [
        {
            "timeFrame": "1 day",
            "tasks": [
                {
                    "enabled": true,
                    "data": "Liquids only"
                },
                {
                    "enabled": true,
                    "data": "Report any headaches"
                }
            ]
        },
        {
            "timeFrame": "4 day",
            "tasks": [
                {
                    "enabled": true,
                    "data": "Can start eating anything"
                },
                {
                    "enabled": false,
                    "data": "Gradual exercise"
                },
                {
                    "enabled": true,
                    "data": "Find new doctor"
                }
            ]
        }
    ]
};



export default function PdfView() {
    const [checklistData] = useState(mockData);

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    });

    const element = (
        <html>
            <body>
            <style>
                {`
                .heading4 {
                background: darkgreen;
                color: white;
                }
                pre {
                padding: 10px;
                }`}
            </style>
            <QRImage style={{width:"100px", height:"100px"}} text="hello" />
            <h1>Pre-Operative</h1>
            {
                checklistData.pre.map(
                    (val) => 
                    <div>
                        {val.timeFrame} After Surgery
                        {val.tasks.map((task) => <>{task.enabled && <div style={{fontSize:"12px", margin:"10px"}}> {task.data} </div>} </>)} 
                    </div>
                )
            }
            <h1>Post-Operative</h1>
            {
                checklistData.post.map(
                    (val) => 
                    <div>
                        {val.timeFrame} After Surgery
                        {val.tasks.map((task) => <>{task.enabled && <div style={{fontSize:"12px", margin:"10px"}}> {task.data} </div>} </>)} 
                    </div>
                )
            }
            </body>
        </html>
    );

    const html = ReactDOMServer.renderToStaticMarkup(element);

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
            <Html >{html}</Html>
            </Page>
        </Document>
    );

    return (
        <div className="full-checklist-container">
            <PDFViewer>
                <MyDocument />
            </PDFViewer>
        </div>
    )
}
