import React from 'react'
import Navbar from '../components/Navbar';
import UserSettingsComponent from '../components/UserSettingsComponent'
import '../components/FullChecklist.css'



//Do Authentication here before rendering
//let { id } = useParams();

export default function UserSettings() {

    return (
        <div className="full-checklist-view">
            <Navbar/>
            <UserSettingsComponent />
        </div>
    )
}
