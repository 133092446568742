import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { AiOutlineEdit } from 'react-icons/ai';
import './Checklist.css';
import uuid from 'react-uuid'
import Modal from 'react-modal';

export default function ChecklistHistory(props) {
    const [nextVisibility, setNextVisibility] = useState("hidden");
    const [prevVisibility, setPrevVisibility] = useState("hidden");
    const [currEditId, setCurrEditId] = useState(null);
    const [currEditVal, setCurrEditVal] = useState(null);
    const [showModal, setshowModal] = useState(false);
    const [history, setHistory] = useState([]);
    const [offset, setOffset] = useState(0);
    const [canGoNext, setCanGoNext] = useState(true);
    const referenceRef = useRef();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const customStyles = {
        content: {
            width: '15%',
            minWidth: "300px",
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            borderRadius: '9px'
        },
    };

    useEffect( () => {
        const token = localStorage.getItem("token");
        const pingUrl = "/api/checklistHistory";

        fetch(pingUrl,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.message !== undefined) {
                navigate("/dashboard");
            }
            if(data.length < 10) setCanGoNext(false);
            else setCanGoNext(true);

            setHistory(data);
        })
    },[])

    useEffect( () => {
        canGoNext ? setNextVisibility("visible") : setNextVisibility("hidden");
        offset === 0 ? setPrevVisibility("hidden") : setPrevVisibility("visible");
    },[offset, canGoNext])

    const getNewPage = function(val){
        const token = localStorage.getItem("token");
        const pingUrl = "/api/nextChecklistHistory";
        const page = offset + val;
        const pkg = {
            offset: page
        }

        fetch(pingUrl,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => response.json())
        .then(data => {
            if(data.length < 10) setCanGoNext(false);
            else setCanGoNext(true);

            setOffset(page);
            setHistory(data);
        })
    }

    const getReferenceCode = function(code, id){
        if(code === "") return <span className="pointer" onClick={() => showModalHandler(code, id)}><AiOutlineEdit className="icon-pack"/> {t("Add Reference")}</span>;
        else return <span className="pointer" onClick={() => showModalHandler(code, id)}><AiOutlineEdit className="icon-pack"/> {code}</span>;
    }

    const showModalHandler = function(code, id){
        setCurrEditId(id);
        setCurrEditVal(code);
        setshowModal(true);
    }

    const changeReference = function(){
        //TODO: Error handling, find out what that tooltip is on success and add it
        const token = localStorage.getItem("token");
        const pingUrl = "/api/changeHistoryReference";
        const pkg = {
            id: currEditId,
            ref: referenceRef.current.value
        }

        fetch(pingUrl,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(pkg)
        })
        .then(response => response.json())
        .then(data => {
            setshowModal(false);
            getNewPage(0);
        })
    }

    
    return (
        <div className="checklist-container">

            <div className="history-header">
            
                <div className="history-title"> 
                    <span>{t("Code History")}</span>
                </div>

                <div className="history-back">
                    <span onClick={() => {navigate("/dashboard")}} className="full-checklist-back-action">&#x2190; {t("Back")}</span> 
                </div>
            </div>

            <div className="table-wrapper">
                <table id="history-table" className="checklist-table">
                    <tbody>
                        <tr>
                            <th>{t("Name")}</th>
                            <th>{t("Procedure")}</th>
                            <th>{t("Created Date")}</th>
                            <th>{t("Usage")}</th>
                            <th>{t("Code")}</th>
                            <th>{t("Reference")}</th>
                        </tr>

                        {
                            history.map(
                                (item, idx) => (
                                    <tr id="history-table-row" className="checklist-table-row" key={uuid()}>
                                        <td className="td-mid">{item.name}</td>
                                        <td className="td-mid">{item.procedure}</td>
                                        <td className="td-mid">{item.date}</td>
                                        <td className="td-mid">{item.usage}</td>
                                        <td className="td-mid history-code">{item.code}</td>
                                        <td className="td-mid history-code">{getReferenceCode(item.ref, item.id)}</td>
                                    </tr>
                                )
                            )
                        }

                    </tbody>
                </table>
            </div>
            <div className="navigation-section">
                <span className="navigation-buttons">
                    <button style={{visibility: prevVisibility}} onClick={() => getNewPage(-10)}>&#8592; {t("Previous")} </button> <button style={{visibility: nextVisibility}} onClick={() => getNewPage(10)}>{t("Next")} &#8594;</button>
                </span>
            </div>


            <Modal isOpen={showModal} style={customStyles} contentLabel="Minimal Modal Example">
                <div className="success-modal-box">
                    <div className="success-modal-header">{t("Edit Reference")}</div>
                    <input className="modal-input" ref={referenceRef} maxLength="64" defaultValue={currEditVal} type="text"/>
                    <div className="confirm-modal-buttons">
                        <button onClick={() => changeReference()} className="confirm-modal-btn">{t("Okay")}</button>
                        <button onClick={() => setshowModal(false)} className="cancel-modal-btn">{t("Cancel")}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}