import React from 'react'
import Navbar from '../components/Navbar';
import ChecklistHistory from '../components/ChecklistHistory'
import '../components/FullChecklist.css'



//Do Authentication here before rendering
//let { id } = useParams();

export default function UserSettings() {

    return (
        <>
        <div className="full-checklist-view">
            <Navbar/>
            <ChecklistHistory />
        </div>
        <div className="footer loginFooter">
            ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
        </div>
        </>
    )
}
