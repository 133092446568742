import * as React from "react";

const authContext = React.createContext();

export default function useAuth() {

  const isAuthed = function(){
      let currSeconds = new Date().getTime() / 1000;
      let tokenSeconds = parseFloat(localStorage.getItem("tokenTime"));
      const token = localStorage.getItem("token");
      if(token !== null && token !==''){
        if(currSeconds < tokenSeconds + 86300) return true;
      }

      return false;
  }

  return {
    isAuthed,
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("tokenTime");
      return;
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function AuthConsumer() {
  return React.useContext(authContext);
}