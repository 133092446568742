import React, { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine, RiLogoutBoxRLine } from 'react-icons/ri';
import { BsGearFill } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { downIcon, personIcon, settingsIcon, logoutIcon } from "../assets/IconConstants";

export default function NavDropdown(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const wrapperRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
          document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setIsVisible(false);
        }
    };

    const dropDownHandler = function(){
        isVisible ? setIsVisible(false) : setIsVisible(true);
    }

    const navToItem = function(location){
      navigate(location);
    }

    const logoutHandler = function(){
        localStorage.removeItem("token");
        navigate("/");
    }

  return (
        <span className="navDropbtn" ref={wrapperRef}>
            <ul className="myDropdown" onClick={() => dropDownHandler()} >{personIcon} &nbsp; {localStorage.getItem("name")} {downIcon}</ul>
            <div id="myDropdown" className="dropdown-content">
                    { isVisible && <><ul onClick={() => navToItem("/settings")}>{settingsIcon} {t('Settings')}</ul> <ul onClick={() => logoutHandler()} >{logoutIcon} {t('Logout')}</ul> </>}
            </div>
        </span>
  )
}

