import React from 'react'
import { Navigate } from "react-router-dom";
import useAuth from "../components/useAuth.js";
import { useTranslation } from 'react-i18next';

export default function RequireAuth({ children }) {
    const { isAuthed } = useAuth();
    const { i18n } = useTranslation();

    if(localStorage.getItem("language") !== undefined){
        const currLanguage = localStorage.getItem("language");
        if(currLanguage !== i18n.language){
            i18n.changeLanguage(localStorage.getItem("language"));
        }
    }

    return isAuthed() === true ? children : <Navigate to="/login" replace />;
}