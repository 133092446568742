import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { BiCog } from 'react-icons/bi';
import './Checklist.css';

export default function UserSettingsComponent(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const nameRef = useRef();
    const clinicRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const surgeonRef = useRef();
    const oldPasswordRef = useRef();
    const newPasswordRef = useRef();
    const newPasswordConfirmRef = useRef();
    const countryRef = useRef();
    const cityRef = useRef();
    const addressRef = useRef();
    const accountTypeRef = useRef();
    const languageRef = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [clinic, setClinic] = useState("");
    const [surgeonType, setSurgeonType] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [language, setLanguage] = useState("");
    const [oldLang, setOldLang] = useState("");
    const [accountType, setAccountType] = useState("");

    useEffect( () => {
        const token = localStorage.getItem("token");
        const pingUrl = "/api/userInfo";

        fetch(pingUrl,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        .then(response => response.json())
        .then(data => {
            setName(data.name);
            setEmail(data.email);
            setClinic(data.clinic);
            setSurgeonType(data.surgeonType);
            setPhoneNumber(data.phone);
            setCountry(data.country);
            setCity(data.city);
            setAddress(data.address);
            setAccountType(data.accountType);
            setLanguage(data.language);
            setOldLang(data.language);
        })
    },[])

    const applyChanges = function(){
        let pkgChanged = false;
        let passChanged = false;
        let changePackage = {};
        let passwordPackage = {};

        if(nameRef.current.value !== name){
            pkgChanged = true;
            changePackage["name"] = nameRef.current.value;
            localStorage.setItem('name', nameRef.current.value);
        }
        if(clinicRef.current.value !== clinic){
            pkgChanged = true;
            changePackage["clinic"] = clinicRef.current.value;
        }
        if(phoneRef.current.value !== phoneNumber){
            pkgChanged = true;
            changePackage["phone"] = phoneRef.current.value;
        }
        if(surgeonRef.current.value !== surgeonType){
            pkgChanged = true;
            changePackage["surgeonType"] = surgeonRef.current.value;
        }

        if(countryRef.current.value !== country){
            pkgChanged = true;
            changePackage["country"] = countryRef.current.value;
        }
        if(cityRef.current.value !== city){
            pkgChanged = true;
            changePackage["city"] = cityRef.current.value;
        }
        if(addressRef.current.value !== address){
            pkgChanged = true;
            changePackage["address"] = addressRef.current.value;
        }
        if(languageRef.current.value !== oldLang){
            pkgChanged = true;
            changePackage["language"] = languageRef.current.value;
            localStorage.setItem('language', languageRef.current.value);
        }


        if(newPasswordRef.current.value !== "" || newPasswordConfirmRef.current.value !== ""){
            if(oldPasswordRef.current.value === ""){
                alert(t("Please put in current password"));
                return;
            }else if(newPasswordRef.current.value !== newPasswordConfirmRef.current.value){
                alert(t("Passwords do not match"));
                return;
            }

            if(!isValidPassword(newPasswordRef.current.value)){
                alert(t("ValidPassword"));
                return;
            }

            passwordPackage["oldPassword"] = oldPasswordRef.current.value;
            passwordPackage["newPassword"] = newPasswordRef.current.value;
            passChanged = true;
        }
        
        if(pkgChanged || passChanged) postNewValues(changePackage, passwordPackage, pkgChanged, passChanged);
        else alert(t("No values changed"));
    }

    const isValidPassword = function(password){
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        return regularExpression.test(password);
    }

    const postNewValues = function(changePackage, passwordPackage, pkgChanged, passChanged){
        let postPackage = {};
        const token = localStorage.getItem("token");
        if(passChanged){
            postPackage["newPassword"] = passwordPackage.newPassword;
            postPackage["oldPassword"] = passwordPackage.oldPassword;
        }

        if(pkgChanged){
            postPackage["data"] = changePackage;
        }

        fetch("/api/userInfo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(postPackage)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            alert(data.message);
            window.location.reload(false);
        })
        .catch(error => alert(error))
    }

    return (
        <div className="checklist-container">

            <div className="checklist-header">
                <span className="widget-name"> <BiCog style={{position: "relative", top:"2px"}}/> <span style={{fontSize: "18px"}}> {t("User Settings")} </span> </span>
            </div>

            <div className="settings-container">

                <div className="settings-modify-section">
                    <div className="settings-section-header"> {t("Contact Details")} </div>
                    <div className="settings-input-section">
                        <div className="settings-inputs-list">
                            <span>
                                {t("Full Name")}
                                <div> <input maxLength="50" defaultValue={name} ref={nameRef}></input> </div>
                            </span>
                            <span>
                                {t("Country")}
                                <div> <input maxLength="50" defaultValue={country} ref={countryRef}></input> </div>
                            </span>
                            <span>
                                {t("Email")}
                                <div> <input disabled maxLength="50" defaultValue={email} ref={emailRef}></input> </div>
                            </span>
                        </div>

                        <div className="settings-inputs-list">
                            <span>
                                {t("Practice Name")}
                                <div> <input maxLength="50" defaultValue={clinic} ref={clinicRef}></input> </div>
                            </span>
                            <span>
                                {t("City")}
                                <div> <input maxLength="50" defaultValue={city} ref={cityRef}></input> </div>
                            </span>
                            <span>
                                {t("Type of Practice")}
                                <div> <input maxLength="50" defaultValue={surgeonType} ref={surgeonRef}></input> </div>
                            </span>
                        </div>

                        <div className="settings-inputs-list">
                            <span>
                                {t("Phone Number")}
                                <div> <input maxLength="50" defaultValue={phoneNumber} ref={phoneRef}></input> </div>
                            </span>
                            <span>
                                {t("Address")}
                                <div> <input maxLength="50" defaultValue={address} ref={addressRef}></input> </div>
                            </span>
                            <span>
                                {t("Account Type")}
                                <div> <input disabled maxLength="50" defaultValue={accountType} ref={accountTypeRef}></input> </div>
                            </span>
                        </div>

                        <div className="settings-inputs-list">
                            <span>
                                {t("Language")}
                                <div>
                                    <select onChange={e => setLanguage(e.target.value)} value={language} ref={languageRef}>
                                        <option value="en">English</option>
                                        <option value="es">Spanish</option>
                                        <option value="fr">French</option>
                                        <option value="ar">Arabic</option>
                                        <option value="hi">Hindi</option>
                                        <option value="ja">Japanese</option>
                                        <option value="pa">Punjabi</option>
                                        <option value="pt">Portuguese</option>
                                        <option value="tr">Turkish</option>
                                        <option value="zh">Chinese</option>
                                    </select>
                                </div>
                            </span>
                        </div>

                    </div>
                </div>
                <input style={{visibility: "hidden", width: 0, height: 0}}></input>
                <input style={{visibility: "hidden", width: 0, height: 0}} type="password"></input>
                <div className="settings-modify-section">
                    <div className="settings-section-header"> {t("Change Password")} </div>
                    <div className="settings-inputs-list">
                        <span>
                            {t("Current Password")}
                            <div> <input maxLength="50" ref={oldPasswordRef} type="password"></input> </div>
                        </span>
                    </div>
                    <div className="settings-inputs-list">
                        <span>
                            {t("New Password")}
                            <div> <input maxLength="50" ref={newPasswordRef} type="password"></input> </div>
                        </span>
                    </div>
                    <div className="settings-inputs-list">
                        <span>
                            {t("Confirm New Password")}
                            <div> <input maxLength="50" ref={newPasswordConfirmRef} type="password"></input> </div>
                        </span>
                    </div>
                </div>
            </div>

            <div className="settings-save-button">
                <button onClick={() => applyChanges()}>{t("Save")}</button>
            </div>



        </div>
    )
}