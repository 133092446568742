import React from 'react'
import Navbar from '../components/Navbar';
import FullChecklist from '../components/FullChecklist'
import '../components/FullChecklist.css'



//Do Authentication here before rendering
//let { id } = useParams();

export default function ChecklistContainer() {

    return (
        <div className="full-checklist-view">
            <Navbar/>
            <FullChecklist />
            <div className="footer" style={{backgroundColor: "white", marginTop: "2px"}}>
                ©2022 Chiron Systems Inc.&nbsp; &nbsp; &nbsp;  |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp;  <a href="https://pogamed.com/home/terms-of-use/" target="_blank">Terms of Service</a>
            </div>
        </div>
    )
}
